import alertInfo from '@comp/alert_info';
import basicUpload from '@comp/basic_upload';
import { mapGetters, mapActions } from 'vuex';
import { commonMethods } from '../../table_config/table-common-methods';
export default {
    components: {
        alertInfo,
        basicUpload
    },
    props: {
        itemId: {
            type: String,
            default: '1'
        }
    },
    computed: {
        ...mapGetters([
            'heritageId',
            'userName',
            'userId'
        ])
    },
    data() {
        // 记录id
        return {
            commonMethods,
            id: '',
            type: '',
            infodata: {
                dyms: '',
                zysx: ''
            },
            formdata: {
                isNewVersion: false,
                saveJson: {},
                metaJson: []
            },
            mapList: [], // 地图URL列表
            isEdit: false, // 是否可编辑
            isCheck: false, // 是否可审核
            btnType: 0, // 0 查看  1 编辑  2 审核
            version: '', // 版本更新--版本号
            fid: '', // 新建子要素--父级要素编码
            ItemID: '', // 当前是哪项
            basicType: '',
            deleteFileID: [], // 删除附件的ID
        };
    },
    created() {
        // 监测云 嵌入 保护展示与环境整治工程记录信息
        if (this.$route.name == "abnormalWarning") {
            this.id = this.detail.id;
            this.type = this.detail.type;
            this.basicType = this.detail.basicType;
            this.version = this.detail.version;
            this.ItemID = this.detail.ItemID;
        } else {
            this.id = this.$route.query.id;
            this.type = this.$route.query.type;
            this.basicType = this.$route.query.basicType;
            this.version = this.$route.query.version;
            this.ItemID = this.$route.query.ItemID;
            this.fid = this.$route.query.fid;
            this.isFold = this.$route.query.isFold;
        }

        if (this.version) {
            this.formdata.isNewVersion = true;
        }

        if (this.ItemID === '28') {
            this.formdata.itemID = this.$route.query.ytID || '2802';
        }
    },
    mounted() {
        if (this.id) {
            this.getFromData();
        }
        this.contralBtn();
        this.getInfo();
    },
    methods: {
        ...mapActions([
            'saveBasicData',
            'getGatherData',
            'GetDefinitionAndNote',
            'DeleteBhqhYcqOrHcq',
            'updateDataSHZT',
            'deleteBasicFile',
            'getYcsygnMap',
            'GetGLXList',
            'recalculateDatacode',
        ]),
        // 获取文保单位列表
        async getWBDWList() {
            let result = await this.GetGLXList({
                itemcode: 'wbdw',
                lm: ''
            });
            this.wbdwList = result.ResultValue;
        },
        async getFromData() {
            let params = {
                heritageId: this.heritageId,
                itemid: this.formdata.itemID,
                id: this.id,
            };
            let result = await this.getGatherData(params);
            if (result.issuccess) {
                Object.assign(this.formdata.saveJson, result.resultvalue.savejson && result.resultvalue.savejson[0]);
                this.formdata.metaJson = result.resultvalue.metajson || [];
                if (this.formdata.itemID === '3') {
                    this.operateData('read');
                }
                // 33 申遗承诺
                if (this.formdata.saveJson.cnwcsj === '无') {
                    this.cnwcsj = true;
                } else if (this.formdata.saveJson.cnwcsj) {
                    this.cnwcsj_date = this.formdata.saveJson.cnwcsj;
                }
                // 8文保单位
                if (this.formdata.itemID === '8') {
                    let data = this.formdata.saveJson.wbdwjbxx;
                    if (data.jd && data.wd) {
                        data.jwd = data.jd + ',' + data.wd;
                    }
                }
                // 7遗产要素清单
                if (this.formdata.saveJson.jd) {
                    this.formdata.saveJson.jwd = this.formdata.saveJson.jd + ',' + this.formdata.saveJson.wd;
                }
                // 12病害调查记录
                if (this.formdata.saveJson.bhjd) {
                    this.formdata.saveJson.bhjwd = this.formdata.saveJson.bhjd + ',' + this.formdata.saveJson.bhwd;
                }
                // 保护工程
                if (this.formdata.itemID === '24') {
                    this.getMetaJsonData();
                    this.getArea();
                }
                // 19 保护管理规划
                if (this.formdata.itemID === '19') {
                    this.getMetaJsonData();
                }
                // 数据配置
                this.getDataNext();
                this.contralBtn();
            }
        },
        // 数据配置
        getDataNext() { },
        changeSJMJ(value) {
            if (value === '不公开') {
                this.showBackBtn();
            } else {
                this.contralBtn();
            }
        },
        // 只显示 返回按钮
        showBackBtn() {
            this.snButton.buttonData.forEach(item => {
                if (item.name !== '返回') {
                    item.isShow = false;
                }
            });
        },
        // 控制按钮显示
        contralBtn() {
            // type: 0 => 新建; 1 => 编辑; 2 => 查看 3=> 审核
            // btnType: 0 查看  1 编辑  2 审核
            let btnType = (this.type === '0' || this.type === '1') ? '1' : this.type === '3' ? '2' : '0';
            this.btnType = btnType;
            this.isEdit = btnType === '1';
            this.isCheck = this.commonMethods.isPower('2') && btnType === '2'; // 有审核权限并且点击审核按钮进入
            this.snButton.buttonData.forEach(item => {
                if (item.name == "保存") {
                    if (this.type === '999' &&
                        (this.formdata.saveJson.shzt == 0 || this.formdata.saveJson.shzt == 2) &&
                        localStorage.roleId.split(",").includes(window.ROLE_ID.CJGLY)
                    ) {
                        this.isEdit = true //输入框可以编辑
                        item.isShow = true //详情页面显示 保存按钮
                    } else {
                        item.isShow = false
                    }
                } else {
                    item.isShow = item.editType.split(',').indexOf(btnType) > -1;
                }
            });
        },
        handleChange(type, value) {
            this[type](value);
        },
        async dealFromData(shzt) {
            if (this.ItemID === '24') {
                this.setMetaJsonData(shzt);
            }
            // if (this.ItemID === '19') {
            //     this.formdata.metaJson = this.formdata.metaJson.concat(this.metaData);
            // }
            let fromdata = this.formdata;
            let fileID = [];
            let files = {};
            this.formdata.metaJson.forEach((file) => {
                file.Key = file.code;
                if (!files[file.Key]) {
                    files[file.Key] = [];
                }
                files[file.Key].push(file.jcsjid);
            });
            Object.keys(files).forEach(key => {
                fileID.push({ Key: key, "Ids": files[key].join(',') });
            });
            fromdata.fileID = fileID;
            fromdata.heritageId = this.heritageId;
            fromdata.userName = this.userName;
            fromdata.userId = localStorage.userId;

            if (this.deleteFileID.length > 0) {
                let IDs = this.deleteFileID.join(',');
                // 删除表单附件，主要针对19保护管理规划和24保护展示与环境整治工程记录
                let arg = {
                    fjid: IDs,
                    ItemID: this.formdata.itemID
                };
                let result = await this.deleteBasicFile(arg);
            }
        },
        // 注意特殊字符处理
        // 超级管理员 保存数据 不修改数据状态
        async saveDataAdmin() {
            if (this.ItemID === '19') {
                this.formdata.metaJson = this.formdata.metaJson.concat(this.metaData);
            }
            this.dealFromData(1);
            let basicValidPromise = this.$refs.formdata.validate()
            Promise.all([
                basicValidPromise
            ]).then(() => {
                this.saveData();
            }).catch(() => {
                this.common.showMsg("请检查是否填写完整！", 'warning')
            });
        },
        // 保存暂不提交未做验证
        async saveNosubmit() {
            if (this.ItemID === '19') {
                this.formdata.metaJson = this.formdata.metaJson.concat(this.metaData);
            }
            this.dealFromData(1);
            if (this.formdata.saveJson.wbdwjbxx) { // 文保单位
                this.formdata.saveJson.wbdwjbxx.shzt = 1;
            } else if (this.formdata.saveJson.mainlist) {
                this.formdata.saveJson.mainlist[0].shzt = 1;
            } else if (this.ItemID === '24') { // 保护工程，避免保存附件时修改表单的shzt
                this.formdata.saveJson.shzt = this.formdata.saveJson.shzt === 2 && !this.specialChanged ? 2 : 1;
            } else {
                this.formdata.saveJson.shzt = 1;
            }

            let basicValidPromise = this.$refs.formdata.validate()
            Promise.all([
                basicValidPromise
            ]).then(() => {
                this.saveData();
            }).catch(() => {
                this.common.showMsg("请检查是否填写完整！", 'warning')
            });

        },
        // 保存并提交
        async saveSubmit() {
            let saveJson = this.formdata.saveJson;
            if (this.ItemID === '19') {
                this.formdata.metaJson = this.formdata.metaJson.concat(this.metaData);
            }
            let metaJson = this.formdata.metaJson;
            if (saveJson.sjmj) {
                // 存在 数据密级 的情况，验证 是否为：公开。
                if (saveJson.sjmj === '0' || saveJson.sjmj === '公开') {
                    if (this.notNeedFile || metaJson.length > 0) {
                        let basicValidPromise = this.$refs.formdata.validate();
                        let metaValidPromise = this.$refs.uploadFile && this.$refs.uploadFile.validate();
                        Promise.all([
                            basicValidPromise,
                            metaValidPromise
                        ]).then(([basicValid, metaValid]) => {
                            this.saveForm();
                        }).catch(() => {
                            this.common.showMsg("请检查是否填写完整！", 'warning')
                        });
                    } else {
                        this.common.showMsg("请上传附件", 'warning')
                    }
                }
            } else {
                // 不存在 数据密级 的情况 直接验证表单
                let basicValidPromise;
                if (this.ItemID === '24') { // 验证多个表单
                    basicValidPromise = this.$refs.formdata.validate() && this.$refs.formdata1.validate() && this.$refs.formdata3.validate();
                } else {
                    basicValidPromise = this.$refs.formdata.validate();
                }
                Promise.all([
                    basicValidPromise
                ]).then(([basicValid]) => {
                    this.saveForm();
                }).catch(() => {
                    this.common.showMsg("请检查是否填写完整！", 'warning')
                });
            }
        },
        // 审核通过
        savePass(value) {
            let id = typeof value === 'string' ? value : '';
            this.saveSHZT(2, id);
        },
        // 审核不通过
        saveNoPass(value) {
            let id = typeof value === 'string' ? value : '';
            this.saveSHZT(3, id);
        },
        // 基础数据审核通过(保护展示与环境整治工程记录信息)
        saveBasePass() {
            this.saveSHZT(2);
        },
        // 基础数据审核不通过(保护展示与环境整治工程记录信息)
        saveBaseNoPass() {
            this.saveSHZT(3);
        },
        saveSHZT(zt, id) { // id为保护展示与环境整治工程记录信息中审核每一个附件的id，id不存在即为审核整条的基础数据
            this.$confirm('审核提交后不能更改，你确认要提交吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 获取未审核通过的原因
                if (Number(zt) === 3) {
                    this.$prompt('请输入审核未通过原因', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    }).then(({ value }) => {
                        if (value == null || value.trim().length == 0) {
                            this.common.showMsg("输入无效,请重新审核", 'info')
                        } else {
                            this.updateSjzt(zt, id, value)
                        }
                    }).catch(() => {
                        this.common.showMsg("已取消", 'info')
                    });
                } else {
                    // 审核通过
                    this.updateSjzt(zt, id)
                }
            }).catch(() => {
                this.common.showMsg("已取消审核", "info")
            });
        },
        // 审核状态改变
        async updateSjzt(zt, id, reason) {
            let sjid = '';
            if (id) {
                sjid = id;
            } else if (this.formdata.saveJson && this.formdata.saveJson.id) {
                sjid = this.formdata.saveJson.id;
            } else if (this.formdata.saveJson.wbdwjbxx && this.formdata.saveJson.wbdwjbxx.id) {
                sjid = this.formdata.saveJson.wbdwjbxx.id;
            } else if (this.formdata.saveJson.mainlist && this.formdata.saveJson.mainlist[0].id) {
                sjid = this.formdata.saveJson.mainlist[0].id;
            }
            if (!sjid) {
                this.common.showMsg("审核失败", "error")
                return;
            }
            let itemID = this.ItemID === '28' ? this.$route.query.ytID : this.ItemID;
            let res = await this.updateDataSHZT({
                funId: itemID,
                sjid: sjid,
                shzt: zt,
                shrid: this.userId || localStorage.userId,
                shsj: this.common.getTimeYmdhms(),
                shbtgsm: reason ? reason : "",
                rwid: this.$route.query.RWID
            });
            if (res.IsSuccess) {
                this.common.showMsg("审核成功", "success")
                if (!id) { // 审核某一个附件不用返回列表
                    this.returnList();
                } else {
                    let res = this.formdata.metaJson.filter(item => {
                        return item.id === id;
                    });
                    res[0].shzt = zt;
                    this.getFromData();
                }
            }
        },
        saveForm() {
            this.$confirm('数据提交后不能更改，你确认要提交吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.dealFromData(0);
                if (this.formdata.saveJson.wbdwjbxx) { // 文保单位
                    this.formdata.saveJson.wbdwjbxx.shzt = 0;
                } else if (this.formdata.saveJson.mainlist) {
                    this.formdata.saveJson.mainlist[0].shzt = 0;
                } else if (this.ItemID === '24') { // 保护工程，避免保存附件时修改表单的shzt
                    this.formdata.saveJson.shzt = this.formdata.saveJson.shzt === 2 && !this.specialChanged ? 2 : 0;
                } else {
                    this.formdata.saveJson.shzt = 0;
                }
                this.saveData();
            }).catch(() => {
                this.common.showMsg("已取消提交", 'info')
            });
        },
        dealCJR() {
            // if (!Object.keys(this.formdata.saveJson).includes('CJR')) return;
            if (this.formdata.saveJson.CJR) return;
            this.formdata.saveJson.CJR = this.userName;
        },
        async saveData() {
            if (this.formdata.saveJson.cjrid) {
                this.formdata.saveJson.CJRID = this.formdata.saveJson.cjrid;
            } else {
                this.formdata.saveJson.CJRID = localStorage.getItem('userId');
            }
            // 保护区划界线描述与管理规定 21
            if (Number(this.ItemID) !== 21) {
                this.formdata.saveJson.RWID = this.$route.query.RWID;
            }
            if (this.formdata.itemID === '3') {
                this.operateData('save');
            }
            // 病害调查记录的经纬度处理
            if (this.formdata.saveJson.bhjwd) {
                let data = this.formdata.saveJson.bhjwd.split(',');
                this.formdata.saveJson.bhjd = data[0];
                this.formdata.saveJson.bhwd = data[1];
            }
            // 遗产要素清单的经纬度处理
            if (this.formdata.saveJson.jwd) {
                let data = this.formdata.saveJson.jwd.split(',');
                this.formdata.saveJson.jd = data[0];
                this.formdata.saveJson.wd = data[1];
            }
            // 文保单位的经纬度处理
            if (this.formdata.itemID === '8' && this.formdata.saveJson.wbdwjbxx.jwd) {
                let data = this.formdata.saveJson.wbdwjbxx.jwd.split(',');
                this.formdata.saveJson.wbdwjbxx.jd = data[0];
                this.formdata.saveJson.wbdwjbxx.wd = data[1];
            }
            // 保护区划界线描述与管理规定 数据处理
            if (this.formdata.itemID === '21') {
                this.operateData();
            }
            if (this.version) {
                if (this.formdata.saveJson.wbdwjbxx) {
                    this.formdata.saveJson.wbdwjbxx.bbh = Number(this.version) + 1;
                } else {
                    this.formdata.saveJson.bbh = Number(this.version) + 1;
                }
            }
            if (this.fid) {
                this.formdata.saveJson.fid = this.fid;
            }
            let nameArr = [];
            this.formdata.metaJson.forEach(item => {
                nameArr.push(item.wdmc);
            });
            this.formdata.saveJson.JCSJMC = nameArr.join();
            this.formdata.glycbtid = this.heritageId;
            let res = await this.saveBasicData(this.formdata);
            if (res.IsSuccess) {
                this.common.showMsg("保存成功", 'success')
                this.returnList();
                if (this.type === '0') {
                    // await this.recalculateDatacode({ Datacode: this.formdata.itemID });
                }
            } else {
                this.common.showMsg("保存失败", 'error')
            }
        },
        // 遗产使用功能图
        async getYcsygnMapList() {
            let mc = this.mc;
            let res = await this.getYcsygnMap({ mc, 'itemCode': this.MapitemCode });
            if (res && res.length > 0) {
                res.forEach(ele => {
                    if (ele.Dtfbsj) {
                        ele.Dtfbsj = ele.Dtfbsj.substr(0, 7);
                    }
                });
                this.mapList = res;
            }
        },
        // 返回列表
        returnList() {
            this.$router.push({
                name: 'basicTable',
                params: {
                    ItemID: this.formdata.itemID,
                    MC: 'null',
                    basicType: this.$route.query.basicType
                }
            });
        },
        // 获取定义描述与注意事项
        async getInfo() {
            let res = await this.GetDefinitionAndNote({
                itemId: this.formdata.itemID
            });
            if (res.IsSuccess) {
                this.infodata.dyms = res.ResultValue.DYMS;
                this.infodata.zysx = res.ResultValue.ZYSX;
            } else {
                this.infodata.dyms = "";
                this.infodata.zysx = "";
            }
        }
    },

};