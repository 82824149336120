const snButton = {
    buttonData: [ // 只有超级管理员采显示的保存按钮 只保存数据 不修改数据状态
        {
            isShow: true,
            btnType: 'button',
            operateType: 'saveDataAdmin',
            name: '保存',
            round: true,
            backColor: '#28cdd9',
            color: '#fff'
        }, {
            isShow: true,
            btnType: 'button',
            operateType: 'saveNosubmit',
            editType: '1',
            name: '保存暂不提交',
            round: true,
            backColor: '#28cdd9',
            color: '#fff'
        }, {
            isShow: true,
            btnType: 'button',
            operateType: 'saveSubmit',
            editType: '1',
            name: '保存并提交',
            round: true,
            backColor: '#6f32ff',
            color: '#fff'
        }, {
            isShow: true,
            btnType: 'button',
            operateType: 'savePass',
            editType: '2',
            name: '审核通过',
            round: true,
            backColor: '#28cdd9',
            color: '#fff'
        }, {
            isShow: true,
            btnType: 'button',
            operateType: 'saveNoPass',
            editType: '2',
            name: '审核不通过',
            round: true,
            backColor: '#6f32ff',
            color: '#fff'
        }, {
            isShow: true,
            btnType: 'button',
            operateType: 'returnList',
            editType: '0,1,2',
            name: '返回',
            round: true,
            backColor: '#ff9066',
            color: '#fff'
        }]
};
export default snButton;